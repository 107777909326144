import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Table from 'components/Table'
import { appIdSelector, accessIdSelector, isTourEnabledSelector } from 'modules/Login/selectors/user'
import CustomCellHighlight from 'components/CustomCellHighlight'
import { getHistoryNegotiation } from './service/financial'
import { installmentSelector } from './selectors/installments'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'
import { getAcceptTermNegotiation } from './service/acceptanceterm'
import { isLoadingAcceptTermSelector } from './selectors/acceptTerm'
import { CircularProgress } from '@material-ui/core'
import { set } from 'layouts/actions'
import { injectIntl } from 'react-intl'

export const STYLE_CELLS = {
  paddingRight: 20,
  paddingLeft: 0
}

export default injectIntl(({ intl: { messages } }) => {
  const tableRef = useRef()
  const dispatch = useDispatch()

  const appId = useSelector(appIdSelector)
  const accessId = useSelector(accessIdSelector)

  const { data, loading, error } = useSelector(installmentSelector)
  const isTourOpen = useSelector(isTourEnabledSelector)

  const isLoadingTerm = useSelector(isLoadingAcceptTermSelector)
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const title = messages['history-negotiation-table-title']
  const [agreementIdLoading, setAgreementIdLoading] = useState(0)

  useEffect(() => {
    if (isTourOpen) {
      return
    }
    dispatch(getHistoryNegotiation({ accessId, appId }))
  }, [accessId, dispatch, appId, isTourOpen])

  const columns = () => {
    return isSmall ? [
      { title: messages['history-negotiation-id-agreement'], field: 'agreementId', cellStyle: STYLE_CELLS },
      { title: messages['history-negotiation-value'], field: 'amountAgreementFormated', cellStyle: STYLE_CELLS, customSort: (param1, param2) => parseFloat(param1.amountAgreement) - parseFloat(param2.amountAgreement) },
    ] :
      [
        { title: messages['history-negotiation-id-agreement'], field: 'agreementId', cellStyle: STYLE_CELLS },
        { title: messages['history-negotiation-date'], field: 'agreementDateFormated', cellStyle: STYLE_CELLS, customSort: (param1, param2) => Date.parse(param1.agreementDate) - Date.parse(param2.agreementDate) },
        { title: messages['history-negotiation-payment-way'], field: 'paymWayId', cellStyle: STYLE_CELLS },
        { title: messages['history-negotiation-value'], field: 'amountAgreementFormated', cellStyle: STYLE_CELLS, customSort: (param1, param2) => parseFloat(param1.amountAgreement) - parseFloat(param2.amountAgreement) },
        { title: messages['history-negotiation-student'], field: 'nameFormatted', cellStyle: STYLE_CELLS },
        { title: messages['history-negotiation-origin'], field: 'origin', cellStyle: STYLE_CELLS },
      ]
  }

  if (!isEmpty(error)) {
    return (
      <div style={{ textAlign: 'center' }}>
        Não há histórico de acordos.
      </div>
    )
  }

  const onDownloadTerm = (item) => {
    setAgreementIdLoading(item.agreementId)

    dispatch(getAcceptTermNegotiation(appId, item.listInvoices[0], item.agreementId))
      .then((result) => {
        if (result.fileurl) {
          window.open(result.fileurl, '_blank', 'noopener')
        }
        setAgreementIdLoading(0)
      })
      .catch((error) => {
        const message = error.response && error.response.data && error.response.data.message
        dispatch(set(message || messages['message-error-unexpected']))
        setAgreementIdLoading(0)
      })
  }


  return (
    <div>
      <Table
        isLoading={loading}
        tableRef={tableRef}
        components={{
          Cell: (props) => <CustomCellHighlight {...props} tableref={tableRef} />
        }}
        actions={[
          rowData => {
            return ({
              icon: () => isLoadingTerm && agreementIdLoading === rowData.agreementId ? <CircularProgress size={18} /> : <FontAwesomeIcon icon={faFileSignature} />,
              tooltip: messages['history-negotiation-tooltip-term'],
              onClick: e => onDownloadTerm(rowData),
              id: 'btn-his-down'
            })
          }
        ]}
        localization={{
          header: {
            actions: messages['history-negotiation-header-term']
          }
        }}
        options={{
          showTextRowsSelected: false,
          search: true,
          paging: false,
          sorting: true,
          headerStyle: {
            fontSize: 15,
            paddingRight: 1
          }
        }}
        columns={columns()}
        data={data || []}
        title={title}
      />
    </div>)
})
