import React, { lazy } from "react";
import { isArray } from "lodash";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import CreditCard from "@material-ui/icons/CreditCard";
import {
  FINANCIAL,
  DASHBOARD,
  FINANCIAL_SET_MESSAGE,
  FINANCIAL_PRINT_BILLET,
  FINANCIAL_NEGOTIATION,
  PAYMENTS_NEGOTIATION_ADMIN,
  FINANCIAL_PAID_INSTALLMENTS,
  FINANCIAL_TAX,
  INSTALLMENT_CONFIGURATION,
  SEND_SMS,
  DASHBOARD_ADMIN,
} from "utils/permissions";

import Build from "@material-ui/icons/Build";
import Home from "@material-ui/icons/Home";
import Login from "./modules/Login";
import LoginAD from "./modules/Login/LoginAD";
import Money from "@material-ui/icons/MonetizationOn";
import DescriptionIcon from "@material-ui/icons/Description";
import Paid from "./modules/Finance/Paid";
import Pay from "./modules/Finance/Pay";
import Registers from "./modules/Home";
import Success from "./modules/Success";
import Tax from "./modules/Tax";
import Negotiation from "./modules/Negotiation/Finance";
import History from "./modules/Negotiation/History";
import PaymentsList from "modules/Negotiation/PaymentsList";
import SMS from "modules/Admin/SMS";
import Dashboard from "./modules/Admin/Dashboard";
import PaymentTypes from "./modules/Finance/PaymentTypes";
import ValidarCarteirinha from "./pages/ValidarCarteirinha/index";
const Message = lazy(() => import("./modules/Admin/Message"));
const Billet = lazy(() => import("./modules/Admin/Billet"));
const ConfigureInstallment = lazy(() =>
  import("./modules/Admin/ConfigureInstallment")
);
const HomeSMO = React.lazy(() => import('./pages/Home/Home'));
const HomePortal = React.lazy(() => import('./pages/HomePortal/HomePortal'));
const MudancaMetodoDePagamento = React.lazy(() => import('./pages/MudancaMetodoDePagamento/MudancaMetodoDePagamento'));
const Declaracoes = React.lazy(() => import('./pages/Declaracoes/Declaracoes'));
const Historico = React.lazy(() => import('./pages/Historico/Historico'));
const Acordo = React.lazy(() => import('./pages/Acordo/Acordo'));
const ParcelasPagas = React.lazy(() => import('./pages/ParcelasPagas/ParcelasPagas'));
const ParcelasAbertas = React.lazy(() => import('./pages/ParcelasAbertas/ParcelasAbertas'));
const Inicio = React.lazy(() => import('./pages/Inicio/Inicio'));
const MomentoEscolar = React.lazy(() => import('./pages/MomentoEscolar/MomentoEscolar'));
const MomentoEscolarResponsavel = React.lazy(() => import('./pages/MomentoEscolarResponsavel'));
const MomentoEscolarAlterarDadosResponsavel = React.lazy(() => import('./pages/MomentoEscolarAlterarDadosResponsavel'));
const MomentoEscolarServicos = React.lazy(() => import('./pages/MomentoEscolarServicos/MomentoEscolarServicos'));
const MomentoEscolarPagamento = React.lazy(() => import('./pages/MomentoEscolarPagamento/MomentoEscolarPagamento'));
const CriarSenha = React.lazy(() => import('./pages/CriarSenha/CriarSenha'));
const InformeSeusDados = React.lazy(() => import('./pages/InformeSeusDados/InformeSeusDados'));
const DadosDoEstudante = React.lazy(() => import('./pages/DadosDoEstudante/DadosDoEstudante'));
const AnexarDocumentos = React.lazy(() => import('./pages/AnexarDocumentos/AnexarDocumentos'));
const RedefinirSenha = React.lazy(() => import('./pages/RedefinirSenha/RedefinirSenha'));
const CadastroConcluido = React.lazy(() => import('./pages/CadastroConcluido/CadastroConcluido'));
const CheckoutConcluido = React.lazy(() => import('./pages/CheckoutConcluido/CheckoutConcluido'));
const CheckoutReservaConcluido = React.lazy(() => import('./pages/CheckoutReservaConcluido/CheckoutReservaConcluido'));
const MomentoEscolarReservaSemValor = React.lazy(() => import('./pages/MomentoEscolarReserva/MomentoEscolarReservaSemValor'));
const MeusCartoes = React.lazy(() => import('./pages/MeusCartoes/MeusCartoes'));


//TO DO: Na rota de redireiconamento de SUCCESS e FAIL existe um tratamento no web.config
// para que seja feito o redireicionamento para a mesma o url, evitar o uso de urls com success e fail
export const PATH_ROUTE = {
  //Portal Routes
  HOME: "/home/portal",
  LOGINSSO: "/login/sso",
  LOGIN: "/login",
  LOGINAD: "/login/admin",
  LOGIN_EXTERNAL: "/login/external",
  LOGIN_EXTERNALSMO: "/portalsmo",
  SSOCALBACK: "/:id/ssocallback",
  SUCCESS: "/success",
  FAIL: "/fail",
  FINANCE: "/financeiro/pagar",
  PAY: "/financeiro/parcelas/pagar",
  PAID: "/financeiro/parcelas/pagas",
  NEGOTIATION: "/negociacao",
  NEGOTIATION_FINANCE: "/negociacao/acordo",
  NEGOTIATION_HISTORY: "/negociacao/historico",
  NEGOTIATION_PAYMENT: "/negociacao/pagamentos",
  REGISTRATION: "/financeiro/matriculas",
  CONTACT: "/contato",
  ADMIN_BILLET: "/admin/boletos",
  ADMIN_MESSAGE: "/admin/mensagem",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN: "/admin",
  TAX: "/impostos",
  CONFIGURE_INSTALLMENT: "/admin/permitir-parcelamento",
  SMS: "/admin/sms",
  PAYMENT_TYPES: "/formas-de-pagamento",
  MY_CARDS: "/meus-cartoes",

  // SMO Routes
  HOME_SMO: "/",
  VALIDAR_CARTEIRINHA: "/validar-carteirinha",
  HOME_PORTAL: "/portal-financeiro",
  MUDANCA_METODO_DE_PAGAMENTO: "/portal-financeiro/mudanca-metodo-de-pagamento",
  DECLARACOES: "/portal-financeiro/declaracoes",
  HISTORICO: "/portal-financeiro/negociacoes/historico",
  ACORDO: "/portal-financeiro/negociacoes/acordo",
  PARCELAS_PAGAS: "/portal-financeiro/parcelas/pagas",
  PARCELAS_ABERTAS: "/portal-financeiro/parcelas/abertas",
  INICIO: "/inicio",
  INFORME_SEUS_DADOS: "/InformeSeusDados",
  DADOS_DO_ESTUDANTE: "/DadosDoEstudante",
  ANEXAR_DOCUMENTOS: "/AnexarDocumentos",
  REDEFINIR_SENHA: "/RedefinirSenha",
  CRIAR_SENHA: "/CriarSenha",
  MOMENTO_ESCOLAR: "/MomentoEscolar",
  MOMENTO_ESCOLAR_RESPONSAVEL: "/MomentoEscolarResponsavel",
  MOMENTO_ESCOLAR_ALTERAR_DADOS_RESPONSAVEL: "/MomentoEscolarAlterarDadosResponsavel",
  MOMENTO_ESCOLAR_SERVICOS: "/MomentoEscolarServicos",
  CADASTRO_CONCLUIDO: "/CadastroConcluido",
  CHECKOUT_CONCLUIDO: "/CheckoutConcluido",
  CHECKOUT_RESERVA_CONCLUIDO: "/CheckoutReservaConcluido",
  MOMENTO_ESCOLAR_PAGAMENTO: "/MomentoEscolarPagamento",
  MOMENTO_ESCOLAR_RESERVA_SEM_VALOR: "/MomentoEscolarReservaSemValor",
};

const smoRoutes = [
  {
    id: "home",
    title: "Login",
    path: PATH_ROUTE.HOME_SMO,
    Component: HomeSMO,
    showMenu: false,
    exact: true,
    public: true,
  },
  {
    id: "validar-carteirinha",
    title: "Validação de Carteirinha",
    path: PATH_ROUTE.VALIDAR_CARTEIRINHA,
    Component: ValidarCarteirinha,
    showMenu: false,
    exact: true,
    public: true,
  },
  {
    id: "home-portal-financeiro",
    title: "Home Portal Financeiro",
    path: PATH_ROUTE.HOME_PORTAL,
    Component: HomePortal,
    showMenu: false,
    exact: true,
    permissions: [DASHBOARD],
  },
  {
    id: "mudanca-metodo-de-pagamento",
    title: "Mudança Método de Pagamento",
    path: PATH_ROUTE.MUDANCA_METODO_DE_PAGAMENTO,
    Component: MudancaMetodoDePagamento,
    showMenu: false,
    exact: true,
    permissions: [FINANCIAL],
  },
  {
    id: "declaracoes",
    title: "Declarações",
    path: PATH_ROUTE.DECLARACOES,
    showMenu: false,
    exact: true,
    Component: Declaracoes,
    permissions: [FINANCIAL_TAX],
  },
  {
    id: "historico",
    title: "Histórico",
    path: PATH_ROUTE.HISTORICO,
    showMenu: false,
    exact: true,
    Component: Historico,
    permissions: [FINANCIAL_NEGOTIATION],
  },
  {
    id: "negociacao-acordo",
    title: "Acordo",
    path: PATH_ROUTE.ACORDO,
    Component: Acordo,
    showMenu: false,
    exact: true,
    permissions: [FINANCIAL_NEGOTIATION],
  },
  {
    id: "parcelas-pagas",
    title: "Parcelas pagas",
    path: PATH_ROUTE.PARCELAS_PAGAS,
    Component: ParcelasPagas,
    showMenu: false,
    exact: true,
    permissions: [FINANCIAL_PAID_INSTALLMENTS],
  },
  {
    id: "parcelas-abertas",
    title: "Parcelas abertas",
    path: PATH_ROUTE.PARCELAS_ABERTAS,
    Component: ParcelasAbertas,
    showMenu: false,
    exact: true,
    permissions: [FINANCIAL],
  },
  {
    id: "inicio",
    title: "Inicio",
    path: PATH_ROUTE.INICIO,
    Component: Inicio,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Inicio",
    breadcrumb: "Inicio",
  },
  {
    id: "informe-seus-dados",
    title: "Informe seus dados",
    path: PATH_ROUTE.INFORME_SEUS_DADOS,
    Component: InformeSeusDados,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Informe seus dados",
    breadcrumb: "Informe seus dados",
  },
  {
    id: "dados-do-estudante",
    title: "Dados do estudante",
    path: PATH_ROUTE.DADOS_DO_ESTUDANTE,
    Component: DadosDoEstudante,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Dados do estudante",
    breadcrumb: "Dados do estudante",
  },
  {
    id: "anexar-documentos",
    title: "Anexar documentos",
    path: PATH_ROUTE.ANEXAR_DOCUMENTOS,
    Component: AnexarDocumentos,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Anexar documentos",
    breadcrumb: "Anexar documentos",
  },
  {
    id: "redefinir-senha",
    title: "Redefinir senha",
    path: PATH_ROUTE.REDEFINIR_SENHA,
    Component: RedefinirSenha,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Redefinir senha",
    breadcrumb: "Redefinir senha",
  },
  {
    id: "criar-senha",
    title: "Criar senha",
    path: PATH_ROUTE.CRIAR_SENHA,
    Component: CriarSenha,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Criar senha",
    breadcrumb: "Criar senha",
  },
  {
    id: "momento-escolar",
    title: "Momento escolar",
    path: PATH_ROUTE.MOMENTO_ESCOLAR,
    Component: MomentoEscolar,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Momento escolar",
    breadcrumb: "Momento escolar",
  },
  {
    id: "momento-escolar-responsavel",
    title: "Momento escolar responsavel",
    path: PATH_ROUTE.MOMENTO_ESCOLAR_RESPONSAVEL,
    Component: MomentoEscolarResponsavel,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Momento escolar responsavel",
    breadcrumb: "Momento escolar responsavel",
  },
  {
    id: "momento-escolar-alterar-dados-responsavel",
    title: "Momento escolar alterar dados responsavel",
    path: PATH_ROUTE.MOMENTO_ESCOLAR_ALTERAR_DADOS_RESPONSAVEL,
    Component: MomentoEscolarAlterarDadosResponsavel,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Momento escolar alterar dados responsavel",
    breadcrumb: "Momento escolar alterar dados responsavel",
  },
  {
    id: "momento-escolar-servicos",
    title: "Momento escolar servicos",
    path: PATH_ROUTE.MOMENTO_ESCOLAR_SERVICOS,
    Component: MomentoEscolarServicos,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Momento escolar servicos",
    breadcrumb: "Momento escolar servicos",
  },
  {
    id: "cadastro-concluido",
    title: "Cadastro Concluído",
    path: PATH_ROUTE.CADASTRO_CONCLUIDO,
    Component: CadastroConcluido,
    showMenu: false,
    Icon: Home,
    exact: true,
    name: "Cadastro Concluído",
    public: true,
    breadcrumb: "Cadastro Concluído",
  },
  {
    id: "checkout-concluido",
    title: "Checkout Concluído",
    path: PATH_ROUTE.CHECKOUT_CONCLUIDO,
    Component: CheckoutConcluido,
    showMenu: false,
    Icon: Home,
    exact: true,
    name: "Checkout Concluído",
    public: true,
    breadcrumb: "Checkout Concluído",
  },
  {
    id: "checkout-reserva-concluido",
    title: "Checkout Reserva Concluído",
    path: PATH_ROUTE.CHECKOUT_RESERVA_CONCLUIDO,
    Component: CheckoutReservaConcluido,
    showMenu: false,
    Icon: Home,
    exact: true,
    name: "Checkout Reserva Concluído",
    public: true,
    breadcrumb: "Checkout Reserva Concluído",
  },
  {
    id: "momento-escolar-pagamento",
    title: "Momento escolar pagamento",
    path: PATH_ROUTE.MOMENTO_ESCOLAR_PAGAMENTO,
    Component: MomentoEscolarPagamento,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Momento escolar pagamento",
    breadcrumb: "Momento escolar pagamento",
  },
  {
    id: "momento-escolar-reserva-sem-valor",
    title: "Reserva",
    path: PATH_ROUTE.MOMENTO_ESCOLAR_RESERVA_SEM_VALOR,
    Component: MomentoEscolarReservaSemValor,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Reserva",
    breadcrumb: "Reserva",
  },
  {
    id: "wallet",
    title: "Meus Cartões",
    path: PATH_ROUTE.MY_CARDS,
    Component: MeusCartoes,
    showMenu: false,
    Icon: Home,
    exact: true,
    public: true,
    name: "Meus Cartões",
    breadcrumb: "Meus Cartões",
  }
];

const portalRoutes = [
  {
    id: "home",
    title: "Home",
    path: PATH_ROUTE.HOME,
    Component: Registers,
    showMenu: true,
    Icon: Home,
    exact: true,
    name: "Home",
    breadcrumb: "Home",
    permissions: [DASHBOARD],
  },
  {
    id: "login",
    title: "Login",
    path: [PATH_ROUTE.LOGIN, PATH_ROUTE.LOGINSSO, PATH_ROUTE.SSOCALBACK],
    Component: Login,
    regexPath: new RegExp("(^/login/sso?)|(^/login)|(^/*.*/ssocallback)", "g"),
    showMenu: false,
    public: true,
    exact: true,
    name: "Login",
  },
  {
    id: "loginRedirect",
    title: "Login",
    path: [PATH_ROUTE.LOGIN_EXTERNAL],
    Component: Login,
    showMenu: false,
    public: true,
    exact: false,
    name: "Login",
  },
  {
    id: "loginRedirectSMO",
    title: "Login",
    path: [PATH_ROUTE.LOGIN_EXTERNAL, PATH_ROUTE.LOGIN_EXTERNALSMO],
    Component: Login,
    showMenu: false,
    public: true,
    exact: false,
    name: "Login",
  },
  {
    id: "loginAD",
    title: "Login adm",
    path: [PATH_ROUTE.LOGINAD],
    Component: LoginAD,
    showMenu: false,
    public: true,
    exact: true,
    name: "Login Administrador",
  },
  {
    id: "admin",
    path: PATH_ROUTE.ADMIN,
    showMenu: true,
    Icon: Build,
    exact: true,
    name: "Administração",
    permissions: [FINANCIAL_SET_MESSAGE],
    children: [
      {
        id: "message",
        title: "Cad. mensagem",
        path: PATH_ROUTE.ADMIN_MESSAGE,
        Component: Message,
        showMenu: true,
        Icon: Build,
        exact: true,
        name: "Cadastrar Mensagem",
        breadcrumb: "Cadastrar Mensagem",
        children: [],
        permissions: [FINANCIAL_SET_MESSAGE],
      },
      {
        id: "billet",
        title: "Imp. boletos",
        path: PATH_ROUTE.ADMIN_BILLET,
        Component: Billet,
        showMenu: true,
        Icon: Build,
        exact: true,
        name: "Imprimir Boletos",
        breadcrumb: "Imprimir boletos",
        children: [],
        permissions: [FINANCIAL_PRINT_BILLET],
      },
      {
        id: "dashboard-admin",
        title: "Painel de análise",
        path: PATH_ROUTE.ADMIN_DASHBOARD,
        Component: Dashboard,
        showMenu: true,
        Icon: Build,
        exact: true,
        name: "Painel de análise",
        breadcrumb: "Painel de análise",
        children: [],
        permissions: [DASHBOARD_ADMIN],
      },
      {
        id: "configure-installment",
        title: "Permitir Parcelamento",
        path: PATH_ROUTE.CONFIGURE_INSTALLMENT,
        Component: ConfigureInstallment,
        showMenu: true,
        Icon: Build,
        exact: true,
        name: "Permitir Parcelamento",
        breadcrumb: "Permitir Parcelamento",
        children: [],
        permissions: [INSTALLMENT_CONFIGURATION],
      },
      {
        id: "sms",
        title: "Envio de SMS",
        path: PATH_ROUTE.SMS,
        Component: SMS,
        showMenu: true,
        Icon: Build,
        exact: true,
        name: "Envio SMS",
        breadcrumb: "Envio de SMS",
        children: [],
        permissions: [SEND_SMS],
      },
    ],
  },
  {
    id: "finances",
    path: PATH_ROUTE.FINANCE,
    showMenu: true,
    Icon: Money,
    exact: true,
    name: "Financeiro",
    permissions: [FINANCIAL, FINANCIAL_PAID_INSTALLMENTS],
    children: [
      {
        id: "pay",
        title: "Parc. abertas",
        path: PATH_ROUTE.PAY,
        Component: Pay,
        showMenu: true,
        Icon: Money,
        exact: true,
        name: "Parcelas Abertas",
        breadcrumb: "Parcelas Abertas",
        permissions: [FINANCIAL],
        children: [],
      },
      {
        id: "paid",
        title: "Parc. pagas",
        path: PATH_ROUTE.PAID,
        Component: Paid,
        showMenu: true,
        Icon: Money,
        exact: true,
        name: "Parcelas Pagas",
        breadcrumb: "Parcelas Pagas",
        permissions: [FINANCIAL_PAID_INSTALLMENTS],
        children: [],
      },
    ],
  },
  {
    id: "negotiation",
    path: PATH_ROUTE.NEGOTIATION,
    title: "Negociação",
    showMenu: true,
    Icon: AccountBalanceIcon,
    dataTour: "tur-negotitation-menu",
    exact: true,
    name: "Negociação",
    breadcrumb: "Negociação",
    permissions: [FINANCIAL_NEGOTIATION],
    children: [
      {
        id: "negotiarion-finance",
        title: "Acordo",
        dataTour: "tur-negotitation-finance-menu",
        path: PATH_ROUTE.NEGOTIATION_FINANCE,
        Component: Negotiation,
        showMenu: true,
        exact: true,
        name: "Acordo",
        breadcrumb: "Acordo",
        permissions: [FINANCIAL_NEGOTIATION],
      },
      {
        id: "negotiation-history",
        dataTour: "tur-negotitation-history-menu",
        path: PATH_ROUTE.NEGOTIATION_HISTORY,
        title: "Histórico",
        Component: History,
        showMenu: true,
        exact: true,
        name: "Histórico",
        breadcrumb: "Histórico",
        permissions: [FINANCIAL_NEGOTIATION],
      },
      {
        id: "negotiation-payment",
        path: PATH_ROUTE.NEGOTIATION_PAYMENT,
        title: "Pagamentos",
        Component: PaymentsList,
        showMenu: true,
        exact: true,
        name: "Pagamentos",
        breadcrumb: "Pagamentos",
        permissions: [PAYMENTS_NEGOTIATION_ADMIN],
      },
    ],
  },
  {
    id: "success",
    title: "Sucesso pag",
    path: [PATH_ROUTE.SUCCESS, PATH_ROUTE.FAIL],
    showMenu: false,
    exact: true,
    public: true,
    Component: Success,
    name: "Sucesso",
  },
  {
    id: "incomeTax",
    dataTour: "tur-tax-menu",
    title: "Impostos",
    path: PATH_ROUTE.TAX,
    showMenu: true,
    Icon: DescriptionIcon,
    exact: true,
    Component: Tax,
    name: "Declarações",
    permissions: [FINANCIAL_TAX],
  },
  {
    id: "paymentTypes",
    dataTour: "tur-payment-types",
    title: "Mudança de Método de Pagamento",
    path: PATH_ROUTE.PAYMENT_TYPES,
    Component: PaymentTypes,
    showMenu: true,
    Icon: CreditCard,
    exact: true,
    breadcrumb: "Mudança de Método de Pagamento",
    name: "Mudança de Método de Pagamento",
    permissions: [FINANCIAL],
  },
]

const routes = [
  ...portalRoutes,
  ...smoRoutes,
];

export default routes;

export const mapRoutes = (callback, filter_ = (item) => item) =>
  routes
    .map((route) =>
      route.children
        ? route.children.map((ch) => ({ ...ch, parentId: route.id }))
        : []
    )
    .reduce((value, current) => [...value, ...current], [])
    .concat(routes)
    .filter(filter_)
    .map(callback);

const checkPath = (path, route) => {
  if (route.regexPath && path.match(route.regexPath)) {
    return true;
  }
  return isArray(route.path) ? route.path.includes(path) : String(route.path).toLowerCase() === String(path).toLowerCase();
};

export const getTitle = () => {
  const path = window.location.pathname;
  const routeNotChildren = routes.find(
    (route) => !route.children && checkPath(path, route)
  );
  if (routeNotChildren) {
    return document.title = routeNotChildren.title;
  }
  const routeChildren = routes
    .filter((route) => route.children)
    .reduce((current, prev) => [...current, ...prev.children], [])
    .find((route) => checkPath(path, route));

  return document.title = (routeChildren && routeChildren.title) || "";
};
