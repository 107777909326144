import React, { useState } from 'react'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import { Typography, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { formatOwnName } from 'utils/formatString.js'
import useStyles from './style'
import {
  appIdSelector,
  accessIdSelector,
} from 'modules/Login/selectors/user'
import GenderAvatar from 'components/gender'
import { FormattedMessage } from 'react-intl'
import {
  registrationSelector,
  isLoadingRegistrationSelector,
} from 'modules/Finance/PaymentTypes/selectors/registrations'
import { fetchRegistration } from 'modules/Finance/PaymentTypes/services/registration'
import { getPaymentTypeSelected } from 'modules/Finance/PaymentTypes/services/paymentTypes'

export default ({ setSelectedMatriculaId, setRegistrationHasError, setSelectedDataAreaId, setSelectedResponsibleId, setSelectedMatricula }) => {
  const [active, setActive] = useState(0)
  const classes = useStyles()
  const dispatch = useDispatch()
  const appid = useSelector(appIdSelector)
  const accessId = useSelector(accessIdSelector)
  const isLoading = useSelector(isLoadingRegistrationSelector)
  const { data } = useSelector(registrationSelector)

  React.useEffect(() => {
    if (data && data.length == 0) {
      dispatch(fetchRegistration(appid, accessId))
    } else if (data && data.length > 0) {
      selectRegistration(data[0], 0)
    }
    else if (!data) {
      setRegistrationHasError(true)
    }
  }, [data, dispatch, appid, accessId])

  if (isLoading) {
    return <CircularProgress className={classes.studantLoading} />
  }

  const selectRegistration = (student, key) => {
    if (student.responsibles) {
      const responsibleId = student.responsibles[student.responsibles.length - 1].responsibleid

      setSelectedMatriculaId(student.registrationid)
      setSelectedDataAreaId(student.dataareaid)
      setSelectedResponsibleId(responsibleId)
      setSelectedMatricula(student)

      setRegistrationHasError(false)

      dispatch(getPaymentTypeSelected(appid, student.registrationid, student.dataareaid, responsibleId))
        .catch((error) => {
          setRegistrationHasError(true)
        });

      setActive(key)
    }
    else {
      setRegistrationHasError(true)
    }
  }

  if ((data && data.length === 0) || !data) {
    return (
      <Typography gutterBottom component='p' color={'textPrimary'}>
        <FormattedMessage id='finance-negotiation-comp-student-not-found' />
      </Typography>
    )
  } else if (data && data.length > 0) {
    return (
      <Grid container spacing={2}>
        {data.map((student, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              key={i}
              onClick={() => {
                selectRegistration(student, i)
              }}
            >
              <div
                className={classNames(
                  classes.studantInfo,
                  active === i && classes.studantInfoSelected
                )}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <GenderAvatar
                      gender={student.gender}
                      className={classes.studantAvatar}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.studantContent}>
                      <Typography
                        gutterBottom
                        color={"textPrimary"}
                        className={classes.studantName}
                      >
                        {formatOwnName(student.studentname)}
                      </Typography>

                      <Typography
                        variant='caption'
                        gutterBottom
                        color={"textPrimary"}
                      >
                        <b>{student.unity.name}</b>
                      </Typography>

                      <br />
                      <br />

                      {student.services.map((service, i) => {
                        return (
                          <>
                            <Typography
                              variant='caption'
                              gutterBottom
                              color={"textPrimary"}
                              className={classes.productName}
                            >
                              {service.productname}
                            </Typography>
                            <br />
                          </>)
                      })}

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )
        })}
      </Grid>
    )
  }
}
