import React, { Fragment } from 'react'
import Registrations from './components/Registrations'
import { makeStyles } from '@material-ui/core/styles'
import Pay from '../Finance/Pay'
import BannerNotificationContainer from './components/BannerNotificationContainer'

const useStyle = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(3)
  }
}))

const Home = ({ history }) => {
  const classes = useStyle()

  return (
    <Fragment>
      <div>
        <BannerNotificationContainer />
      </div>
      <div className={classes.margin}>
        <Pay history={history} />
      </div>
      <div className={classes.margin}>
        <Registrations />
      </div>
    </Fragment>
  )
}


export default Home
