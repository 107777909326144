import React, { Fragment } from "react";
import classNames from "classnames";
import {
  Dialog,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import useStyles from "./style";

export default ({ open, onClose, selectedUnidade }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="automatic-debit-dialog-title"
      disableBackdropClick
      classes={{ paper: !fullScreen && classes.paperScrollPaper }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          className={classNames(!fullScreen && classes.buttonClose)}
          aria-label="close-payment"
          id="ico-mdl-pag-fch"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <Grid
        container
        justify="space-between"
        direction="row"
        alignItems="center"
        className={classes.content}
      >
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" align="center">
            Cartão de Crédito Recorrente
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.body}>
          <Typography className={classes.subtitle}>
            Crédito recorrente
          </Typography>

          <br />

          <Typography>
            Funciona como uma assinatura recorrente. O pagamento é inserido mês
            a mês na data de vencimento da fatura do seu cartão,{" "}
            <b>sem comprometer o limite total de crédito.</b>
          </Typography>

          <br />

          {(selectedUnidade != 1304) && (
          <Typography>
            Com esta opção, você ganha <b>5% de desconto</b> na parcela do serviço
            regular e não precisa se preocupar com datas de vencimento, afinal o
            pagamento é <b>automático</b>!
          </Typography>
          )}
          <br />

          <Typography>
          <b>✈</b> E mais: você ainda <b>acumula pontos</b> em programas de milhagens que participar.
          </Typography>

          <br />

          <Typography>
          Após a conclusão da operação haverá período de carência para nova alteração.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.buttons}>
          <Button
            onClick={handleClose}
            className={classes.button}
            variant="outlined"
            color="secondary"
          >
            Fechar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
